import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div
        className="App"
        style={{ whiteSpace: "nowrap", marginBottom: "48px" }}
      >
        <div style={{ margin: "48px", color: "white", fontSize: "48px" }}>
          the EDM political compass
        </div>
        <div>
          <iframe
            src="https://open.spotify.com/embed/track/1yI3Brey1oRQ7ZuNRlGxUA"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="false"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/1mNSomylino1Hoaw3MzCC8"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/6jzqY97VGs6UqEhAVOuGRz"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/3Q2M6sNKDwoqZGBvwdFqZr"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/7BKLCZ1jbUBVqRi2FVlTVw"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <br />
          <iframe
            src="https://open.spotify.com/embed/track/7aqRSYVNbSfMQMzlY7lQPS"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/5QTlj4L7ODIKNnTkhDE16p"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/2sEeRKHVONJGERCwKCW2ix"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/4TIkSdsNSfqpuq6ZYvCjAz"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/67wkHfeE9pKUoElc2MHofG"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <br />
          <iframe
            src="https://open.spotify.com/embed/track/2u5VqOCnWoUYZyCEM5B8JJ"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/6VouvfyygVMuODigWmheJT"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/0DiWol3AO6WpXZgp0goxAV"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/3OaunNUlXXs5e2PXtNAzzG"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/56yyecUavZFNRc9W5t0P8O"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <br />
          <iframe
            src="https://open.spotify.com/embed/track/2nHBfIdQ4ndFNaWilLdBUN"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/3oWBMOzkjjkCmV6abncBUd"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/5TQxS54o7gw5KOxikHxnUi"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/3T00mhdOYUuM5yiuPs3xhs"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/3FcraZvYZmY7pYxZchm1TK"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <br />
          <iframe
            src="https://open.spotify.com/embed/track/27dEnvYqC5cmy2UNQNnhGv"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/5uDEGI7tsp7DyrazxbwEtO"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/560eluwgcEcAEUFk18fyWS"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/7zyicrdArhk1b51MjwZowi"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <iframe
            src="https://open.spotify.com/embed/track/6RvTtRyn7zVayLUWAJxd5Z"
            width="250"
            height="250"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default App;
